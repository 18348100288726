<template>
  <div class="d-flex flex-wrap">
    <b-button
      v-if="$can('make_deposits', 'Caisse Générale')"
      variant="success"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      @click="toggleModal('credit')"
    >
      Entrée
    </b-button>
    <b-button
      v-if="
        $can('make_withdraws', 'Caisse Générale') ||
        $can('add_request_from_fund_hystory', 'Caisse Générale') ||
        $can('add_project_request_from_fund_hystory', 'Caisse Générale')
      "
      variant="outline-danger"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="ml-1"
      @click="toggleModal('debit')"
    >
      Sortie
    </b-button>

    <!-- Add New Transac Modal -->
    <component
      :is="currentModalComponent"
      :showContent.sync="openModal"
      @addTransac="addTransac"
    />
  </div>
</template>

<script>
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BButton, VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FormDebitHandler from "./transac/DebitHandler.vue";
import FormCreditHandler from "./transac/CreditHandler.vue";

export default {
  name: "TransacModal",
  components: {
    BButton,
    FormDebitHandler,
    FormCreditHandler,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      openModal: false,
      transactTypeClick: "",
    };
  },
  computed: {
    currentModalComponent() {
      return this.transactTypeClick;
    },
  },
  methods: {
    toggleModal(option) {
      if (option == "credit") {
        this.transactTypeClick = "form-credit-handler";
      } else {
        this.transactTypeClick = "form-debit-handler";
      }
      this.openModal = true;
    },
    showToast(message, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: icon,
          variant: variant,
        },
      });
    },
    // send transact request to the API
    async addTransac(data) {
      try {
      const { message } = await store.dispatch(
        "fundHistory/addFundHistory",
        data
      );
      this.handleSuccess(message);
      } catch (e) {
        this.showToast(
            "Une erreur est survenue lors de l'enregistrement de la transaction.",
            "CoffeeIcon",
            "error"
        );
      }
    },
    handleSuccess(message) {
      this.$emit("transacUpdated");
      this.showToast(message, "CheckIcon", "success");
      this.openModal = false;
    },
  },
};
</script>

<style></style>
